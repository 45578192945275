'use strict'

import cryptoJs from 'crypto-js'
let keyOne = 'c8e810994266d4a0d3d2826615bb387f'

export default {
  // 加密函數
  encrypt(word) {
    let key = cryptoJs.enc.Hex.parse(keyOne)
    let enc = ''
    if (typeof word === 'string') {
      enc = cryptoJs.AES.encrypt(word, key, {
        // iv: iv
        mode: cryptoJs.mode.ECB,
        padding: cryptoJs.pad.Pkcs7
      })
    } else if (typeof word === 'object') {
      let data = JSON.stringify(word)
      enc = cryptoJs.AES.encrypt(data, key, {
        // iv: iv
        mode: cryptoJs.mode.ECB,
        padding: cryptoJs.pad.Pkcs7
      })
    }
    let encResult = enc.toString()
    return encResult
  },
  // 解密函數
  decrypt(word) {
    let key = cryptoJs.enc.Hex.parse(keyOne)
      let dec = cryptoJs.AES.decrypt(word, key, {
        // vi: vi
        mode: cryptoJs.mode.ECB,
        padding: cryptoJs.pad.Pkcs7
      })
      let data = cryptoJs.enc.Utf8.stringify(dec).toString()
      return data
  }
}
