<template>
    <div id="app" >
        <!--  通过key设置页面刷新 规矩
                $route.fullPath 得到路由(包含带?的参数)
            :key="$route.fullPath" 如果路由改变就刷新-->
        <!-- v-if="isRouterAlive" -->
        <!-- <router-view v-if="isRouterAlive" /> -->
        <router-view />
    </div>
</template>
<script>
import { getCountryCollection } from '@/api/companyApi.js';
import { getProductTreeList } from '@/api/searchApi.js';
export default {
    provide() {
        return {
            //reload: this.reload,
        };
    },
    data() {
        return {
            //isRouterAlive: true,
        };
    },
    created() {
        this.getImgList();
        this.getprouncts()
        // if (sessionStorage.getItem('store')) {
        //   this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))))
        // }
        // // 页面刷新把vuex数据放到sess里
        // window.addEventListener('beforeunload', () => {
        //   sessionStorage.setItem('store', JSON.stringify(this.$store.state))
        // })
    },
    methods: {
        // reload() {
        //     this.isRouterAlive = false;
        //     this.$nextTick(function() {
        //         this.isRouterAlive = true;
        //     });
        // },
        async getImgList() {
            let res = await getCountryCollection();
            if (res && res.code) {
                this.$store.state.countryImgList = res.data.rows;
                let countryImgList = res.data.rows;
                localStorage.setItem('countryList',JSON.stringify(countryImgList))
            }
        },
        async getprouncts(){
        let res = await getProductTreeList('deep=4');
        // console.log(`res`, res);
        localStorage.setItem('ProductTreeList',JSON.stringify(res.data))
        }
    },
};
</script>
