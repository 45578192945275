import Vue from 'vue'
import VueRouter from 'vue-router'
import bus from '../components/bus'
import store from '@/store';
import { updateSysComLogAndDe } from '@/api/companyApi.js'
Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'home',
    component: () =>
      import('../views/home')
  },
  {
    path: '/users',
    name: 'Users',
    component: () =>
      import('../views/User/index'),
    children: [
      {
        path: 'background',
        name: 'Background',
        component: () =>
          import('../views/User/background'),
      },
      {
        path: 'roles',
        name: 'Roles',
        component: () =>
          import('../views/User/roles'),
      },
      {
        path: 'relations',
        name: 'Relations',
        component: () =>
          import('../views/User/Relations'),
      },
      {
        path: 'partner',
        name: 'Partner',
        component: () =>
          import('../views/User/partner'),
      },

    ]
  },
  {
    path: '/help/aboutUs',
    name: 'AboutUs',
    component: () =>
      import('../views/help/aboutUs')
  },
  {
    path: '/help/privacy',
    name: 'Privacy',
    component: () =>
      import('../views/help/privacy')
  },
  {
    path: '/help/terms',
    name: 'Terms',
    component: () =>
      import('../views/help/terms')
  },
  //公司
  {
    path: '/company',
    name: 'Index',
    component: () =>
      import('../views/company/index'),
    // beforeEnter: (to,from,next) => {
    //   let token = sessionStorage.token;
    //   if(token){
    //       next();
    //   } else {
    //     // 跳转到登录
    //     bus.$emit('handleShowLogin', true)
    //   }
    // },
    children: [
      {
        path: 'import&export',
        name: 'ImportAndExport',
        component: () =>
          import('../views/company/importAndExport/importAndExport'),
        children: [
          {
            path: 'export',
            name: 'Export',
            component: () =>
              import('../views/company/importAndExport/export')
          },
          {
            path: 'import',
            name: 'Import',
            component: () =>
              import('../views/company/importAndExport/import')
          },
          {
            path: 'supplychain',
            name: 'SupplyChain',
            component: () =>
              import('../views/company/importAndExport/supplyChain')
          },
        ]
      },
      {
        path: 'ownership',
        name: 'Ownership',
        component: () =>
          import('../views/company/Ownership/index'),
      },
      {
        path: 'relatedcompanies',
        name: 'RelatedCompanies',
        component: () =>
          import('../views/company/Ownership/related'),
      },
      {
        path: 'investment',
        name: 'Investment',
        component: () =>
          import('../views/company/Ownership/invuestment'),
      },
      {
        path: 'businessoperations',
        name: 'BusinessOperations',
        component: () =>
          import('../views/company/Business/business'),
      },
      {
        path: 'industry',
        name: 'Industry',
        component: () =>
          import('../views/company/Business/Industry'),
      },
      {
        path: 'performance',
        name: 'Performance',
        component: () =>
          import('../views/company/Performance/perfoem'),
      },
      {
        path: 'financialhighlights',
        name: 'FinancialHighlights',
        component: () =>
          import('../views/company/Performance/financial'),
      },
      {
        path: 'balancesheet',
        name: 'BalanceSheet',
        component: () =>
          import('../views/company/Performance/balance'),
      },
      {
        path: 'profit&loss',
        name: 'Profit&Loss',
        component: () =>
          import('../views/company/Performance/profit'),
      },
      {
        path: 'ratioanalysis',
        name: 'RatioAnalysis',
        component: () =>
          import('../views/company/Performance/ratioAnalysis'),
      },
      {
        path: 'creditassessment',
        name: 'CreditAssessment',
        component: () =>
          import('../views/company/Performance/assessment'),
      },
      {
        path: 'companysnapshot',
        name: 'CompanySnapshot',
        component: () =>
          import('../views/company/snapshot'),
      },
      {
        path: 'faq',
        name: 'FAQ',
        component: () =>
          import('../views/company/faq')
      },
      {
        path: 'similarcompanies',
        name: 'SimilarCompanies',
        component: () =>
          import('../views/company/similarCompanies')
      },
      {
        path: 'companyprofile',
        name: 'CompanyProfile',
        component: () =>
          import('../views/company/companyProfile')
      },
      {
        path: 'management',
        name: 'Management',
        component: () =>
          import('../views/company/management')
      },
      {
        path: 'contactInfo',
        name: 'ContactInfo',
        component: () =>
          import('../views/company/contactInfo')
      },
      {
        path: 'history',
        name: 'History',
        component: () =>
          import('../views/company/history')
      },
      {
        path: 'publications',
        name: 'Publications',
        component: () =>
          import('../views/company/publications')
      },
      {
        path: 'newsmedia',
        name: 'NewsMedia',
        component: () =>
          import('../views/company/newsMedia')
      },
      {
        path: 'comparecompanys',
        name: 'CompareCompanys',
        component: () =>
          import('../views/company/compareCompanys')
      },
    ]
  },
  // 
  {
    path: '/company/comparecountry',
    name: 'CompareCountry',
    component: () =>
      import('../views/company/compareCountry')
  },
  // 新闻制裁详情
  {
    path: '/search/newsanction',
    name: 'Newsanction',
    component: () =>
      import('../views/searchNews/newsanction')
  },
  // 制裁

  {
    path: '/sanctions',
    name: 'seanctionIndex',
    component: () =>
      import('../views/sanctions/index'),
    // beforeEnter: (to,from,next) => {
    //   let token = sessionStorage.token;
    //   if(token){
    //       next();
    //   } else {
    //     // 跳转到登录
    //     bus.$emit('handleShowLogin', true)
    //   }
    // },
    children: [
      {
        path: 'OtherOfficalList',
        name: 'OtherOfficalList',
        component: () =>
          import('../views/sanctions/OtherOfficalList/index'),
      },
      {
        path: 'relatedcompanies',
        name: 'seanctionRelatedCompanies',
        component: () =>
          import('../views/sanctions/OtherOfficalList/related'),
      },
      {
        path: 'investment',
        name: 'seanctionInvestment',
        component: () =>
          import('../views/sanctions/OtherOfficalList/invuestment'),
      },
      {
        path: 'ProfileNotes',
        name: 'seanctionProfileNotes',
        component: () =>
          import('../views/sanctions/ProfileNotes/business'),
      },
      {
        path: 'NoteDeltes',
        name: 'NoteDeltes',
        component: () =>
          import('../views/sanctions/ProfileNotes/NoteDeltes'),
      },

      {
        path: 'companysnapshot',
        name: 'sanctionCompanySnapshot',
        component: () =>
          import('../views/sanctions/snapshot'),
      },


      {
        path: 'companyprofile',
        name: 'sanctionCompanyProfile',
        component: () =>
          import('../views/sanctions/companyProfile')
      },
      {
        path: 'Sactionslist',
        name: 'SanctionList',
        component: () =>
          import('../views/sanctions/Sactionslist')
      },
      {
        path: 'Relations',
        name: 'seanctionRelations',
        component: () =>
          import('../views/sanctions/Relations')
      },
      {
        path: 'IDNumbers',
        name: 'IDNumbers',
        component: () =>
          import('../views/sanctions/IDNumbers')
      },
      {
        path: 'Sources',
        name: 'Sources',
        component: () =>
          import('../views/sanctions/Sources')
      },
      {
        path: 'Rouers',
        name: 'Rouers',
        component: () =>
          import('../views/sanctions/Roles/index')
      },
      {
        path: 'OtherRoles',
        name: 'OtherRoles',
        component: () =>
          import('../views/sanctions/Roles/OtherRoles')
      },
      {
        path: 'History',
        name: 'OtherRoles',
        component: () =>
          import('../views/sanctions/Roles/History')
      },
      {
        path: 'Name',
        name: 'Name',
        component: () =>
          import('../views/sanctions/Name/index')
      },
      {
        path: 'Placeofbirth',
        name: 'Placeofbirth',
        component: () =>
          import('../views/sanctions/Name/Placeofbirth')
      },
      {
        path: 'Image',
        name: 'Image',
        component: () =>
          import('../views/sanctions/Name/Image')
      },
      {
        path: 'Addresses',
        name: 'Addresses',
        component: () =>
          import('../views/sanctions/Adresses')
      },






    ]
  },
  //国家
  {
    path: '/country',
    name: 'CountryData',
    component: () =>
      import('../views/country/index'),
    children: [
      {
        path: 'business',
        name: 'Business',
        component: () =>
          import('../views/country/business'),
      },
      {
        path: 'economy',
        name: 'Economy',
        component: () =>
          import('../views/country/economy'),
      },
      {
        path: 'profile',
        name: 'Profile',
        component: () =>
          import('../views/country/profile'),
      },
      {
        path: 'ratings',
        name: 'Ratings',
        component: () =>
          import('../views/country/ratings'),
      },
      {
        path: 'trade',
        name: 'Trade',
        component: () =>
          import('../views/country/trade'),
      },
      {
        path: 'viewhistory',
        name: 'ViewHistory',
        component: () =>
          import('../views/country/viewHistory')
      },
    ]
  },




  //搜索
  {
    path: '/search',
    name: 'Search',
    component: () =>
      import('../views/layout.vue'),
    children: [
      {
        path: 'searchCompany',
        name: 'SearchCompany',
        component: () =>
          import('../views/searchCompany/index'),
      },
      {
        path: 'searchProduct',
        name: 'SearchProduct',
        component: () =>
          import('../views/searchProduct/index'),
      },
      {
        path: 'searchTrade',
        name: 'SearchTrade',
        component: () =>
          import('../views/searchTrade/index'),
      },
      {
        path: 'searchPerson',
        name: 'SearchPerson',
        component: () =>
          import('../views/searchPerson/index'),
      },
      {
        path: 'searchNews',
        name: 'searchNews',
        component: () =>
          import('../views/searchNews/index'),
      },
      {
        path: 'searchSanctions',
        name: 'searchSanctions',
        component: () =>
          import('../views/searchSanctions/index'),
      },
    ]
  },

  //个人中心
  {
    path: '/account',
    name: 'Account',
    component: () =>
      import('../views/myAccount/index.vue'),
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () =>
          import('../views/myAccount/dashboard'),
      },
      {
        path: 'addCredit',
        name: 'AddCredit',
        component: () =>
          import('../views/myAccount/addCredit'),
      },
      {
        path: 'accountSettings',
        name: 'AccountSettings',
        component: () =>
          import('../views/myAccount/accountSettings'),
      },
      {
        path: 'contactInformation',
        name: 'ContactInformation',
        component: () =>
          import('../views/myAccount/contactInformation'),
      },
      {
        path: 'alternateContacts',
        name: 'AlternateContacts',
        component: () =>
          import('../views/myAccount/alternateContacts'),
      },
      {
        path: 'faq',
        name: 'acountFAQ',
        component: () =>
          import('../views/myAccount/faq'),
      },
      {
        path: 'myDatabase',
        name: 'MyDatabase',
        component: () =>
          import('../views/myAccount/mydatabase'),
      },
      {
        path: 'portfolio',
        name: 'Portfolio',
        component: () =>
          import('../views/myAccount/portfolio'),
      },
      {
        path: 'myTag',
        name: 'MyTag',
        component: () =>
          import('../views/myAccount/myTag'),
      },
      {
        path: 'quota',
        name: 'Quota',
        component: () =>
          import('../views/myAccount/quota'),
      },
      {
        path: 'organization',
        name: 'Organization',
        component: () =>
          import('../views/myAccount/organization'),
      },
      {
        path: 'billing',
        name: 'Billing',
        component: () =>
          import('../views/myAccount/billing'),
      },
      {
        path: 'billinginformation',
        name: 'BillingInformation',
        component: () =>
          import('../views/myAccount/billinginformation'),
      },
      {
        path: 'billinginformationtwo',
        name: 'billinginformationtwo',
        component: () =>
          import('../views/myAccount/biliingInformintwo'),
      },
    ]
  },








]

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
  mode: 'hash',
  base: process.env.NODE_ENV == "development" ? process.env.BASE_URL : '',
  // base: '/csi/' ,//测试环境子路径 把csi 测试迁移 访问前缀 正式不变
  routes
})
let startTime = Date.now()
let currentTime
let standingTime = 0
let pageName = []
router.beforeEach((to, from, next) => {


  // 如果to存在，则说明路由发生了跳转
  if (to) {
    if (to.path == '/company/companysnapshot' || to.path == '/country') {
      sessionStorage.removeItem('socket');
    }
    // 清空界面名
    pageName = []
    // 离开界面
    // 第一步：页面跳转后记录一下当前的时间 currentTime
    currentTime = Date.now()
    standingTime = parseInt((currentTime - startTime) / 1000)
    //  console.log(from);

    // from.matched.forEach(routeItem => {
    //   console.log(routeItem.name);
    pageName.push(from.name)
    // })  
    // console.log(pageName);
    // ------------
    // 第二步：在这里把 currentTime - startTime 的 差值 发送给后端
    // ------------

    if (pageName.length > 0) {
      let company = JSON.parse(sessionStorage.getItem('company')) ? JSON.parse(sessionStorage.getItem('company')) : JSON.parse(localStorage.getItem('company'))
      // console.log(company);

      const params = {
        // 界面
        aaaId: from.query.id3a,
        countryCode: from.query.companyCountry,
        pageName: pageName.join("-"),
        companyName: company == null ? '' : company.company_name_en == undefined ? company.companyName : company.company_name_en,
        // 进入界面时间
        startTimed: startTime,
        // gmtCreate:  moment(startTime).format('YYYY-MM-DD HH:mm:ss'),
        // 离开时间
        endTimed: currentTime,
        // gmtLeave: new moment(currentTime).format('YYYY-MM-DD HH:mm:ss'),
        /**
         * 进入或离开状态
         * enter进入
         * exit 离开
         */
        // type: 'exit',
        // 停留时长
        // duration: standingTime
      }
      if (from.path.includes('/company/')) {

        let status = sessionStorage.getItem('token') ? true : false

        if (status) {
          updateSysComLogAndDe(params).then(res => {
            //  console.log(res);
          })
        }

      }
  
    }
    // 第三步：每次都要初始化一下 startTime
    startTime = Date.now()
    pageName = []
    // console.log('======== 分割线 ========')
  }
  if (from) {

    if (from.path == '/company/companysnapshot' || from.path == '/country') {
      sessionStorage.removeItem('socket');
    }



    // 进入界面
    to.matched.forEach(routeItem => {
      pageName.push(routeItem.meta.title)
    })
    if (pageName.length > 0) {
      const param = {
        // 界面
        pageName: pageName.join("-"),
        // 进入界面时间
        gmtCreate: '',

        // gmtCreate:  moment(startTime).format('YYYY-MM-DD HH:mm:ss'),
        // 离开时间
        gmtLeave: '',
        /**
        * 进入或离开状态
        * enter进入
        * exit 离开
        */
        type: 'enter'
      }

    }
  }
  next()
})

export default router
