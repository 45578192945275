import { getpersonnel } from '../../api/userApi'
import crypto from '@/utils/crypto'

import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import {
    Message
  } from 'element-ui'
  const state ={
    userlist: []
  }
  const getters={ 
    userlist1(state){
        return state.userlist
    }
  }
  const mutations={
    setuserlist(state,userlist){
 
 
   state.userlist=userlist
   Vue.set( state.userlist,userlist)
    }
  }
  const actions ={
    getuserlist({commit},params){
        return new Promise((resolve, reject) => {
  
            getpersonnel(params).then(res => {
                // let data = JSON.parse(crypto.decrypt(res))
              if (res.code === true) {
             
              commit('setuserlist',res)
            
               
              
                // resolve(data.data)
              } else {
                // Message({
                //   message: '账户或密码错误！',
                //   type: 'warning'
                // })
                return Promise.reject('error')
              }
            }).catch(err => {
              reject(err)
            })
          })
    }
  }
  export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}