import { getPerformance } from "../../api/companyApi";
import crypto from '@/utils/crypto'
import {
  Message
} from 'element-ui'
const state = {
  cardlist: [
  ],
}
const getters = {
  cardlist1(state) {
    return state.cardlist
  }
}
const mutations = {
  setcardlist(state, cardlist) {
    state.cardlist = cardlist
  }
}
const actions = {
  getcardlist({ commit }, params) {
    return new Promise((resolve, reject) => {

      getPerformance(params).then(res => {
        let data = JSON.parse(crypto.decrypt(res))

        if (data.code === true) {
          let list = [{ icon: require('../../assets/img/company/rev.png'), name: 'Revenue', text: "", class: '#1290C9FF' },
          { icon: require('../../assets/img/company/pro.png'), name: 'Profit', text: "", class: "#55B419FF" },
          { icon: require('../../assets/img/company/gro.png'), name: 'Growth', text: "", class: "#022955FF" },
          { icon: require('../../assets/img/company/LIqui.png'), name: 'Liquidity', text: "", class: '#F56B6BFF' },
          { icon: require('../../assets/img/company/finan.png'), name: 'Financial Structure', text: "", class: '#01DAC3FF' },
          { icon: require('../../assets/img/company/crea.png'), name: 'Credit Opinion', text: "", class: "#6668D1FF" },
          { icon: require('../../assets/img/company/risk.png'), name: 'Risk Indicator', text: "", class: '#FF7600FF' },
          { icon: require('../../assets/img/company/crea.png'), name: 'Solvency index (SI)', text: "", class: '#1290C9FF' },]

          list.map(item => {

            if (item.name == 'Revenue') {
              item.text = data.data.revenue
            }
            if (item.name == 'Profit') {
              item.text = data.data.profit
            }
            if (item.name == 'Growth') {
              item.text = data.data.growth
            }
            if (item.name == 'Financial Structure') {
              item.text = data.data.structure
            }
            if (item.name == 'Credit Opinion') {
              item.text = data.data.creditOpinion
            }
            if (item.name == 'Liquidity') {
              item.text = data.data.liquidity
            }
            if (item.name == 'Risk Indicator') {
              item.text = data.data.riskIndicator
            }
            if (item.name == 'Solvency index (SI)') {
              item.text = data.data.si
            }

          })
          commit('setcardlist', list)



          // resolve(data.data)
        } else {
          commit('setcardlist', [])
          // Message({
          //   message: '账户或密码错误！',
          //   type: 'warning'
          // })
          // return Promise.reject('error')
        }
      }).catch(err => {
        reject(err)
      })
    })
  }
}
export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}