import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/index';
import axios from 'axios'; //引入axios
import ElementUI from 'element-ui';
import FBSignInButton from 'vue-facebook-signin-button'
import 'element-ui/lib/theme-chalk/index.css';

Vue.prototype.$axios = axios; //把axios挂载到vue上
Vue.use(ElementUI);
Vue.use(FBSignInButton)

import '@/assets/css/font.css';
// 引入样式表
import '@/assets/css/styles.css';
// 自适应css
import '@/assets/css/media.css';
Vue.config.productionTip = false;

import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts;


Vue.prototype.$EventBus = new Vue();
import world from '../node_modules/echarts/map/json/world.json';

echarts.registerMap('world', world);
import './filters';
// 下载
import JsonExcel from 'vue-json-excel';
Vue.component('downloadExcel', JsonExcel);

// 全局获取缓存数据
(Vue.prototype.resetSetItem = function(key, newVal) {
    // 创建一个StorageEvent事件
    var newStorageEvent = document.createEvent('StorageEvent');
    const storage = {
        setItem: function(k, val) {
            sessionStorage.setItem(k, val);
            // 初始化创建的事件
            newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
            // 派发对象
            window.dispatchEvent(newStorageEvent);
        },
    };
    // if (key === 'menuItem') {
    //     return storage.setItem(key, newVal);
    // }
    if (key === 'token') {
        return storage.setItem(key, newVal);
    }
}),
    new Vue({
        router,
        store,
        
        beforeCreate() {
            Vue.prototype.$bus = this;
        },
        render: h => h(App),
    }).$mount('#app');
