// 引入封装好的axios
import axios from './request'
// 定义跨域代理路由
const currentUrl = window.location.href;
const regex = /www\./;
const mobil = /mobil\./
const phone =mobil.test(currentUrl) 
const iswww= regex.test(currentUrl) 
// let  api = process.env.NODE_ENV == 'development' ? '/devapi' : 'http://52.81.91.97:8099/dev-api'//new-测试
// let  api = process.env.NODE_ENV == 'development' ? '/devapi' : 'http://52.81.219.235:8088/test-api' //测试
// let  api = process.env.NODE_ENV == 'development' ? '/devapi' : 'http://3.101.104.100:8088/prod-api' 
let api = process.env.NODE_ENV == 'development' ? '/devapi' :iswww?'https://www.csibizsearch.com/prod-api': 'https://csibizsearch.com/prod-api' //生产
if(phone){
  api= process.env.NODE_ENV == 'development' ? '/devapi' : 'https://mobil.csibizsearch.com/prod-api' //生产
}

//登录

export const doLogin = params => {
  return axios.post(api + '/gleagle/user/login', params)

  // return axios({
  //   url: api + '/gleagle/user/login',
  //   method: 'POST',
  //   params,
  //   headers: {
  //     'Content-Type': 'application/json;charset=UTF-8'
  //   }
  // })
}
//登录

export const LoginOut = params => {
  return axios.post(api + '/gleagle/user/loginOut', params)

  // return axios({
  //   url: api + '/gleagle/user/login',
  //   method: 'POST',
  //   params,
  //   headers: {
  //     'Content-Type': 'application/json;charset=UTF-8'
  //   }
  // })
}
//注册
export const register = params => {
  return axios.post(api + '/gleagle/user/addUser', params)
}

export const sendEmailCode = params => {
  return axios.post(api + '/gleagle/user/sendEmailVerification', params)
}
//忘记密码校验code
export const checkForgotCode = params => {
  return axios.post(api + '/gleagle/userInfo/verificationCode', params)
}
//忘记密码
export const forgetPassword = params => {
  return axios.post(api + '/gleagle/userInfo/retrievePassword', params)
}
